import React, { createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const injectionContext = createContext({});

const InjectionContext = ({ children }) => {
  const [injectionData, setInjectionData] = useState();
  const [injectionStatus, setInjectionStatus] = useState(false);
  const [totalInjection, setTotalInjection] = useState([
    {
      key: 0,
      totalGasLift: '- - -',
      totalInjectedWater: '- - -',
    },
  ]);
  const [firs, setFirs] = useState({});
  const [gasLift, setGasLift] = useState([]);
  const [injectedWater, setInjectedWater] = useState([]);

  const location = useLocation();
  const isFradeInstance = instanceName === 'Valente';
  const instanceName = location.pathname.split('/')[2];

  const DataRestructure = (data) => {
    setInjectionData(data);
    setInjectionStatus(data.status);

    setTotalInjection([
      {
        key: 0,
        totalGasLift: data.totalGasLift,
        totalInjectedWater: data.totalWaterInjected,
      },
    ]);

    const gasLiftValues = [];

    if (isFradeInstance) {
      if (Array.isArray(data.gasLiftFields)) {
        data.gasLiftFields.map((gasLift, gasLiftIndex) => {
          const { field, parameters } = gasLift;

          parameters.map((parameter, parameterIndex) => {
            const { values } = parameter;

            if (values) {
              values.map((value, index) => {
                gasLiftValues.push({
                  ...value,
                  field: field,
                  parameter: parameter.parameter,
                  key: `${gasLiftIndex}${parameterIndex}${index}`,
                  assignedGasValue: value.flowVolumeAssigned,
                  assignedGFLValue: value.volumeGFLAssigned,
                  firstItem: index === 0,
                  wellGasInjectionId: value.wellInjectionId,
                });
              });
            }
          });
        });
      } else {
        const { field, parameters } = data.gasLiftFields;

        parameters.map((parameter, parameterIndex) => {
          const { values } = parameter;

          if (values) {
            values.map((value, index) => {
              gasLiftValues.push({
                ...value,
                field: field,
                parameter: parameter.parameter,
                key: `${parameterIndex}${index}`,
                assignedGasValue: value.flowVolumeAssigned,
                assignedGFLValue: value.volumeGFLAssigned,
                firstItem: index === 0,
                wellGasInjectionId: value.wellInjectionId,
              });
            });
          }
        });
      }
    } else {
      if (Array.isArray(data.gasLiftFields)) {
        data.gasLiftFields.map((gasLift, gasLiftIndex) => {
          const { field, parameters } = gasLift;

          parameters.map((parameter, parameterIndex) => {
            const { values } = parameter;

            if (values) {
              values.map((value, index) => {
                gasLiftValues.push({
                  ...value,
                  field: field,
                  parameter: parameter.parameter,
                  key: `${gasLiftIndex}${parameterIndex}${index}`,
                  assignedGasValue: value.flowVolumeAssigned,
                  assignedGFLValue: value.volumeGFLAssigned,
                  wellGasInjectionId: value.wellInjectionId,
                });
              });
            }
          });
        });
      } else {
        const { field, parameters } = data.gasLiftFields;

        parameters.map((parameter, parameterIndex) => {
          const { values } = parameter;

          if (values) {
            values.map((value, index) => {
              gasLiftValues.push({
                ...value,
                field: field,
                parameter: parameter.parameter,
                key: `${parameterIndex}${index}`,
                assignedGasValue: value.flowVolumeAssigned,
                assignedGFLValue: value.volumeGFLAssigned,
                wellGasInjectionId: value.wellInjectionId,
              });
            });
          }
        });
      }
    }

    const waterInjectionValues = [];

    if (isFradeInstance) {
      if (Array.isArray(data.waterInjectedFields)) {
        data.waterInjectedFields.map((waterInjected, waterInjectedIndex) => {
          const { field, firs, parameters } = waterInjected;

          setFirs({ ...firs, [field]: firs * 100 });

          parameters.map((parameter, parameterIndex) => {
            const { values } = parameter;

            if (values) {
              values.map((value, index) => {
                waterInjectionValues.push({
                  ...value,
                  field: field,
                  parameter: parameter.parameter,
                  key: `${waterInjectedIndex}${parameterIndex}${index}`,
                  assignedWFLValue: value.volumeWFLAssigned,
                  assignedWaterValue: value.flowVolumeAssigned,
                  firstItem: index === 0,
                  wellWaterInjectionId: value.wellInjectionId,
                });
              });
            }
          });
        });
      } else {
        const { field, firs, parameters } = data.waterInjectedFields;

        setFirs({ ...firs, [field]: firs * 100 });

        parameters.map((parameter, parameterIndex) => {
          const { values } = parameter;

          if (values) {
            values.map((value, index) => {
              waterInjectionValues.push({
                ...value,
                field: field,
                parameter: parameter.parameter,
                key: `${parameterIndex}${index}`,
                assignedWFLValue: value.volumeWFLAssigned,
                assignedWaterValue: value.flowVolumeAssigned,
                firstItem: index === 0,
                wellWaterInjectionId: value.wellInjectionId,
              });
            });
          }
        });
      }
    } else {
      if (Array.isArray(data.waterInjectedFields)) {
        data.waterInjectedFields.map((waterInjected, waterInjectedIndex) => {
          const { field, firs, parameters } = waterInjected;

          setFirs({ ...firs, [field]: firs * 100 });

          parameters.map((parameter, parameterIndex) => {
            const { values } = parameter;

            if (values) {
              values.map((value, index) => {
                waterInjectionValues.push({
                  ...value,
                  field: field,
                  parameter: parameter.parameter,
                  key: `${waterInjectedIndex}${parameterIndex}${index}`,
                  assignedWFLValue: value.volumeWFLAssigned,
                  assignedWaterValue: value.flowVolumeAssigned,
                  wellWaterInjectionId: value.wellInjectionId,
                });
              });
            }
          });
        });
      } else {
        const { field, firs, parameters } = data.waterInjectedFields;

        setFirs({ ...firs, [field]: firs * 100 });

        parameters.map((parameter, parameterIndex) => {
          const { values } = parameter;

          if (values) {
            values.map((value, index) => {
              waterInjectionValues.push({
                ...value,
                field: field,
                parameter: parameter.parameter,
                key: `${parameterIndex}${index}`,
                assignedWFLValue: value.volumeWFLAssigned,
                assignedWaterValue: value.flowVolumeAssigned,
                wellWaterInjectionId: value.wellInjectionId,
              });
            });
          }
        });
      }
    }

    setGasLift(gasLiftValues);
    setInjectedWater(waterInjectionValues);
  };

  return (
    <injectionContext.Provider
      value={{
        DataRestructure,
        injectionData,
        setInjectionData,
        injectionStatus,
        setInjectionStatus,
        totalInjection,
        setTotalInjection,
        firs,
        setFirs,
        gasLift,
        setGasLift,
        injectedWater,
        setInjectedWater,
      }}
    >
      {children}
    </injectionContext.Provider>
  );
};

export default InjectionContext;
