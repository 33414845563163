import history from '@iso/lib/helpers/history';
import axios from 'axios';

const TOKEN_KEY = 'id_token';

const getToken = () => localStorage.getItem(TOKEN_KEY);

const api = axios.create({
  baseURL: process.env.REACT_APP_API2_URL,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (!token) {
    if (config.url !== 'sessions' && config.method !== 'post') {
      history.push('/');
      window.location.reload(false);
    }
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      if (error.response.status === 401) {
        localStorage.removeItem('id_token');
        localStorage.removeItem('user');
        if (
          error.response.config.url !== 'sessions' &&
          error.response.config.method !== 'post'
        ) {
          history.push('/');
          window.location.reload(false);
        }
      }
    } catch (e) {}
    return Promise.reject(error.response);
  },
);

export default api;
