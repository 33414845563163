import GlobalStyles from '@iso/assets/styles/globalStyle';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/locale/pt_BR';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React from 'react';
import { Provider } from 'react-redux';
import AppProvider from './AppProvider';
import RegexInputsValuesContext from './components/utility/regexFields/regexFileds';
import WellTesteContext from './context/WellTeste';
import CadastrosContext from './context/cadastros';
import BotafogoCadastrosContext from './context/cadastrosBotafogo';
import CalculationsContext from './context/calculations';
import GetsRequestContext from './context/gets';
import ImportXmlContext from './context/importXml';
import ImportXmlFNContext from './context/importXmlFN';
import Instance from './context/instance';
import ModalState from './context/modalState';
import Permissions from './context/permissions';
import TableTypes from './context/tableContext';
import Boot from './redux/boot';
import { store } from './redux/store';
import Routes from './router';
import InjectionContext from './context/injection';
import { BrowserRouter as Router } from 'react-router-dom';

dayjs.extend(customParseFormat);
dayjs.locale('pt-br');

const App = () => (
  <Provider store={store}>
    <Router>
      <AppProvider>
        <GetsRequestContext>
          <WellTesteContext>
            <RegexInputsValuesContext>
              <CadastrosContext>
                <BotafogoCadastrosContext>
                  <Instance>
                    <ImportXmlContext>
                      <CalculationsContext>
                        <TableTypes>
                          <ModalState>
                            <Permissions>
                              <ImportXmlFNContext>
                                <InjectionContext>
                                  <GlobalStyles />
                                  <ConfigProvider
                                    locale={ptBR}
                                    theme={{
                                      token: {
                                        colorInfo: '#0d7c84',
                                        colorLink: '#0d7c84',
                                        colorPrimary: '#0d7c84',
                                      },
                                    }}
                                  >
                                    <Routes />
                                  </ConfigProvider>
                                </InjectionContext>
                              </ImportXmlFNContext>
                            </Permissions>
                          </ModalState>
                        </TableTypes>
                      </CalculationsContext>
                    </ImportXmlContext>
                  </Instance>
                </BotafogoCadastrosContext>
              </CadastrosContext>
            </RegexInputsValuesContext>
          </WellTesteContext>
        </GetsRequestContext>
      </AppProvider>
    </Router>
  </Provider>
);
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
